import styled from 'styled-components';
import { Input } from 'reactstrap';
import { em } from 'polished';

export const Form = styled.div`
    position: relative;
`;

export const Field = styled(Input)`
    line-height: 40px;
    height: 40px;
    padding: 0 4.5em 0 1.4em;
    font-weight: 400;
    color: var(--light);
    font-size: ${em('12px')};
    border: 1px solid var(--light);
    border-radius: 30px;

    &::placeholder {
        color: var(--light);
    }

    &::-webkit-input-placeholder { /* Edge */
        color: var(--light);
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--light);
    }

    @media (max-width: 991px) {
        line-height: 36px;
        height: 36px;
        padding: 0 4.5em 0 1em;
    }
`;

export const Loop = styled.a`
    right: 3px;
    padding: 0 .7em;
    color: var(--primary);
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
        color: var(--primary);
    }
`;

export const Close = styled.button`
    border: 0;
    color: var(--light);
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 3em;
    font-size: .8em;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
`;
