import styled from 'styled-components';

export const ResultSearch = styled.b`
    color: var(--primary);
`;

export const LinkResult = styled.div`
    display: block;

    > a {
        display: block;
        padding: 10px 14px;
        color: var(--light);

        > figure {
            display: inline-block;
            width: 15%;
            padding-right: 15px;
            text-align: center;
            vertical-align: middle;
            margin: 0;

            > img {
                max-width: 50px;
                max-height: 50px;
            }
        }

        > span {
            width: 85%;
            display: inline-block;
            vertical-align: middle;
        } 
    }
`;
