import axios from 'axios';
import { get } from '@src/utils/methods';
import { underLocale } from '@src/utils';

const { CancelToken } = axios;

let cancelGetConfigurationProductListingApi;
let cancelGetMaxPriceApi;
let cancelGetFilterApi;

export function getConfigurationProductListingApi(data, limit = null, offset = null, sort = null, locale = null) {
    const queryParams = { filter: data, group_by: 'model' };

    if (limit) {
        queryParams.limit = limit;
    }

    if (offset) {
        queryParams.offset = offset;
    }

    if (sort) {
        const splitedSort = sort.split('_');
        if (splitedSort[0] === 'name') {
            queryParams.sort_by = JSON.stringify({ [underLocale(locale)]: parseInt(splitedSort[1], 10) });
        } else if (splitedSort[0] === 'price') {
            queryParams.sort_by = JSON.stringify({ 'configurations.min_price': parseInt(splitedSort[1], 10) });
        }
    }

    if (cancelGetConfigurationProductListingApi !== undefined) {
        cancelGetConfigurationProductListingApi();
    }

    return get(`${process.env.NEXT_PUBLIC_REST}configuration`, queryParams, false, new CancelToken((c) => {
        cancelGetConfigurationProductListingApi = c;
    }));
}

export function getMaxPriceApi(data) {
    const queryMaxPrice = {
        filter: data,
        aggregate: JSON.stringify({ min_price: 'max' }),
    };

    if (cancelGetMaxPriceApi !== undefined) {
        cancelGetMaxPriceApi();
    }

    return get(`${process.env.NEXT_PUBLIC_REST}configuration`, queryMaxPrice, false, new CancelToken((c) => {
        cancelGetMaxPriceApi = c;
    }));
}

export function getFilterApi(data) {
    if (cancelGetFilterApi !== undefined) {
        cancelGetFilterApi();
    }

    return get(`${process.env.NEXT_PUBLIC_REST}properties`, { filter: data }, false, new CancelToken((c) => {
        cancelGetFilterApi = c;
    }));
}

// REST API SEARCH PAGE
let cancelGetResultSearchApi;

export function getResultSearchApi(data, limit = null, offset = null, sort = null, locale = null) {
    const querySearch = { filter: data, group_by: 'model' };

    if (limit) {
        querySearch.limit = limit;
    }

    if (offset) {
        querySearch.offset = offset;
    }

    if (sort) {
        const splitedSort = sort.split('_');
        if (splitedSort[0] === 'name') {
            querySearch.sort_by = JSON.stringify({ [underLocale(locale)]: parseInt(splitedSort[1], 10) });
        } else if (splitedSort[0] === 'price') {
            querySearch.sort_by = JSON.stringify({ 'configurations.min_price': parseInt(splitedSort[1], 10) });
        }
    }

    if (cancelGetResultSearchApi !== undefined) {
        cancelGetResultSearchApi();
    }

    return get(`${process.env.NEXT_PUBLIC_REST}configuration`, querySearch, false, new CancelToken((c) => {
        cancelGetMaxPriceApi = c;
    }));
}

export function getMaxPriceSearchPageApi(data) {
    const queryMaxPriceSearchPage = {
        filter: data,
        aggregate: JSON.stringify({ 'configurations.min_price': 'max' }),
        group_by: 'model',
    };

    return get(`${process.env.NEXT_PUBLIC_REST}configuration`, queryMaxPriceSearchPage, false, new CancelToken((c) => {
        cancelGetMaxPriceApi = c;
    }));
}
