/* eslint-disable react/destructuring-assignment */
import Image from 'next/image';
import Link from 'next/link';
import compact from 'lodash/compact';
import * as Styles from './suggestion.styled';

const Suggestion = ({ title, image, link, index_type: indexType, locale }, searchField) => {
    const regField = new RegExp(`(${searchField})`, 'i');
    const highlighting = compact(title.split(regField));

    const generateImg = process.env.NEXT_PUBLIC_SHOP_NAME === 'office' ? image : `products/500x500/${image}`;

    return (
        <Styles.LinkResult>
            <Link href={`/${locale}/${link}`} passHref prefetch={false}>
                <a>
                    {image !== null && indexType === 'configuration'
                        ? (
                            <figure>
                                <Image
                                    src={`${process.env.NEXT_PUBLIC_CDN_IMAGES}${generateImg}`}
                                    width={50}
                                    height={50}
                                    layout="fixed"
                                    alt=""
                                />
                            </figure>
                        )
                        : ''}
                    <span>
                        {highlighting.map((sug) => {
                            if (searchField.toLowerCase() === sug.toLowerCase()) {
                                return <Styles.ResultSearch key={`${sug}-${Math.random()}`}>{sug}</Styles.ResultSearch>;
                            }

                            return <span key={`${sug}-${Math.random()}`}>{sug}</span>;
                        })}
                    </span>
                </a>
            </Link>
        </Styles.LinkResult>
    );
};

export default Suggestion;
