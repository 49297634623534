import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSuggestionsData, setSearchField } from '@src/redux/actions/layoutActions';
import {
    getResultSearchData,
    getMaxPriceSearchPage,
    setEmptyOffset,
    setEmptyMaxprice,
    setEmptyProperty,
    setEmptySort,
} from '@src/redux/actions/catalogActions';
import Search from './search';

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getSuggestionsData,
    setSearchField,
    getResultSearchData,
    getMaxPriceSearchPage,
    setEmptyOffset,
    setEmptyMaxprice,
    setEmptyProperty,
    setEmptySort,
}, dispatch);

export default connect(null, mapDispatchToProps)(Search);
