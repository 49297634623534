/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useRouter } from 'next/router';
import Autosuggest from 'react-autosuggest';
import { useI18n } from '@src/locales';
import { underLocale } from '@src/utils';
import Suggestion from './Suggestion';
import * as Styles from './search.styled';

const themeAuto = {
    container: 'react-autosuggest__container',
    containerOpen: 'react-autosuggest__container--open',
    input: 'react-autosuggest__input',
    inputOpen: 'react-autosuggest__input--open',
    inputFocused: 'react-autosuggest__input--focused',
    suggestionsContainer: 'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList: 'react-autosuggest__suggestions-list',
    suggestion: 'react-autosuggest__suggestion',
    suggestionFirst: 'react-autosuggest__suggestion--first',
    suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
    sectionContainer: 'react-autosuggest__section-container',
    sectionContainerFirst: 'react-autosuggest__section-container--first',
    sectionTitle: 'react-autosuggest__section-title',
};

const Search = ({ isMobile, getSuggestionsData, setEmptyProperty, setEmptyOffset, setEmptySort, setEmptyMaxprice, getResultSearchData, getMaxPriceSearchPage }) => {
    const { locale, push, query } = useRouter();
    const [searchField, setSearchField] = useState('key' in query ? query.key : '');
    const [finalSearch, setFinalSearch] = useState('key' in query ? query.key : '');
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionProducts, setSuggestionProducts] = useState([]);
    const { t } = useI18n();

    function onSuggestionsFetchRequested() {
        //
    }

    function onSuggestionsClearRequested() {
        //
    }

    function onSuggestionSelected(e, { suggestion, method }) {
        if (method === 'enter') {
            push(`/${locale}/${suggestion.link}`);
        }
    }

    function handleResultSearch(e) {
        if (finalSearch !== '') {
            setEmptyProperty();
            setEmptyOffset();
            setEmptySort();
            setEmptyMaxprice();
            getResultSearchData(finalSearch, query.lang || locale);
            getMaxPriceSearchPage(finalSearch, query.lang || locale);
        } else {
            e.preventDefault();
        }
    }

    function handleSearchField(e) {
        const { value } = e.target;
        const data = {
            title: { value, operator: 'typing' },
            reseller: process.env.NEXT_PUBLIC_SHOP_ID,
            language: underLocale(locale),
        };
        const indexType = process.env.NEXT_PUBLIC_SHOP_NAME === 'office' ? ['segment'] : ['segment', 'product_group'];
        const dataSegment = { ...data, index_type: indexType };
        const dataConfiguration = { ...data, index_type: ['configuration'] };

        if (value && value !== '') {
            if (e.charCode === 13) {
                setSearchField('');
                setFinalSearch('');

                window.location = `/${locale}/search?key=${finalSearch}&lang=${locale}`;
                return false;
            }

            setSearchField(value || '');
            setFinalSearch(value || '');

            if (e.charCode === 0) {
                return false;
            }

            getSuggestionsData(dataSegment, 6).then((response) => {
                setSuggestions(response.hits || []);
            });

            getSuggestionsData(dataConfiguration, 3).then((response) => {
                setSuggestionProducts(response.hits || []);
            });
        } else {
            setSearchField(value || '');
            setFinalSearch(value || '');
        }

        return true;
    }

    function handleEmptySearch() {
        setSearchField('');
        setFinalSearch('');
    }

    const inputProps = {
        placeholder: t('FindProduct'),
        value: searchField,
        onChange: (e) => handleSearchField(e),
        onKeyPress: (e) => handleSearchField(e),
        id: `search${isMobile ? '-mobile' : ''}`,
    };

    return (
        <div itemScope itemType="https://schema.org/WebSite">
            <meta itemProp="url" content={process.env.NEXT_PUBLIC_REST} />
            <Styles.Form itemProp="potentialAction" itemScope itemType="https://schema.org/SearchAction">
                <meta itemProp="target" content={`${process.env.NEXT_PUBLIC_REST}search?key={searchField}&lang=${locale}`} />
                <Autosuggest
                    theme={themeAuto}
                    suggestions={[...suggestions, ...suggestionProducts]}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={onSuggestionSelected}
                    getSuggestionValue={(suggestion) => suggestion}
                    renderSuggestion={(suggestion) => Suggestion({ ...suggestion, locale }, searchField)}
                    inputProps={inputProps}
                    itemProp="query-input"
                    type="text"
                    required
                    renderInputComponent={(props) => <Styles.Field {...props} enterKeyHint="search" />}
                />

                {searchField && searchField.length > 0 && (
                    <Styles.Close id="close" type="button" onClick={handleEmptySearch} className="i-close" aria-label="close" />
                )}

                <Styles.Loop
                    id={`loop${isMobile ? '-mobile' : ''}`}
                    href={`/${locale}/search?key=${finalSearch}&lang=${locale}`}
                    onClick={handleResultSearch}
                    className="i-search"
                    aria-label="search"
                />
            </Styles.Form>
        </div>
    );
};

export default Search;
