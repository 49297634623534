import axios from 'axios';
import forEach from 'lodash/forEach';
import last from 'lodash/last';
import { actionCreator, underLocale } from '@src/utils';
import * as CatalogTypes from '../types/catalogTypes';
import {
    getConfigurationProductListingApi, getMaxPriceApi, getMaxPriceSearchPageApi, getFilterApi, getResultSearchApi,
} from '../api/catalogApi';

const shop = process.env.NEXT_PUBLIC_SHOP_NAME;

// UPDATE_CATALOG_PRICE_SUCCESS
export function updateCatalogPrice(price) {
    return {
        type: CatalogTypes.UPDATE_CATALOG_PRICE,
        price,
    };
}

// SET_DEFAULT_PRICE_SUCCESS
export function setDefaultPriceRest(price) {
    return {
        type: CatalogTypes.SET_DEFAULT_PRICE_REST,
        price,
    };
}

export function setRestFilterProperty(originalName, value) {
    return {
        type: CatalogTypes.SET_REST_FILTER_PROPERTY,
        originalName,
        value,
    };
}

export function setOffsetRest(offset) {
    return {
        type: CatalogTypes.SET_OFFSET_REST,
        offset,
    };
}

export function setCurrentCategories(payload) {
    return {
        type: CatalogTypes.SET_CURRENT_CATEGORIES,
        payload,
    };
}

// GET_FILTER_REST_REQUEST
export function getfilterRequest() {
    return {
        type: actionCreator(CatalogTypes.GET_FILTER_REST, 'req'),
    };
}

// GET_FILTER_REST_SUCCESS
export function getfilterSuccess(data) {
    return {
        type: actionCreator(CatalogTypes.GET_FILTER_REST, 'res'),
        data,
    };
}

// GET_FILTER_REST_FAILURE
export function getfilterFailure(error) {
    return {
        type: actionCreator(CatalogTypes.GET_FILTER_REST, 'err'),
        error,
    };
}

export function getFilterData(keepFilters) {
    return (dispatch, getState) => {
        dispatch(getfilterRequest());
        const { categories, level } = getState().catalog.products;

        let params = {};
        const lastCategory = last(categories);

        if (level === 3) {
            params = JSON.stringify({ segment: lastCategory.id, property_type: 'CUSTOMIZATION' });
        } else if (level === 2) {
            if (shop === 'office' || categories[0].id === '63fac81e-9be8-4602-9879-e34a26164a61') {
                params = JSON.stringify({ segment: lastCategory.id, property_type: 'CUSTOMIZATION' });
            } else {
                params = JSON.stringify({ product_group: lastCategory.id, property_type: 'CUSTOMIZATION' });
            }
        } else {
            params = JSON.stringify({ segment: lastCategory.id, property_type: 'CUSTOMIZATION' });
        }

        getFilterApi(params)
            .then((response) => response.data)
            .then((filter) => {
                if (keepFilters) {
                    filter.hits.filter((f) => f.name.original_name !== 'Model').forEach((f) => {
                        dispatch(setRestFilterProperty(f.name.original_name, []));
                    });
                }

                dispatch(getfilterSuccess(filter));
            })
            .catch((e) => {
                if (axios.isCancel(e)) {
                    console.log('Canceled getFilterData');
                } else {
                    dispatch(getfilterFailure());
                }
            });
    };
}

// GET_PRODUCTLISTING_REST_REQUEST
export function getProductListingRequest() {
    return {
        type: actionCreator(CatalogTypes.GET_PRODUCTLISTING_REST, 'req'),
    };
}

// GET_PRODUCTLISTING_REST_SUCCESS
export function getProductListingSuccess(data, current) {
    return {
        type: actionCreator(CatalogTypes.GET_PRODUCTLISTING_REST, 'res'),
        data,
        current,
    };
}

// GET_PRODUCTLISTING_REST_FAILURE
export function getProductListingFailure(error) {
    return {
        type: actionCreator(CatalogTypes.GET_PRODUCTLISTING_REST, 'err'),
        error,
    };
}

export function setOriginalData(url) {
    return {
        type: CatalogTypes.SET_ORIGINAL_DATA,
        url,
    };
}

function getParams(products) {
    const { categories, level } = products;

    let params = {};
    const lastCategory = last(categories);

    if (level === 3) {
        params = { segment: lastCategory.id };
    } else if (level === 2) {
        if (shop === 'office' || lastCategory.id === '63fac81e-9be8-4602-9879-e34a26164a61') {
            params = { segment: lastCategory.id };
        } else {
            params = { product_group: lastCategory.id };
        }
    } else {
        params = { segment: lastCategory.id };
    }

    return params;
}

export function getConfigurationList(locale) {
    return (dispatch, getState) => {
        dispatch(getProductListingRequest());
        const betweenPrice = getState().catalog.restFilter.price;
        const { limit, offset, sort, properties } = getState().catalog.restFilter;

        const params = getParams(getState().catalog.products);

        if (betweenPrice.length > 0 && betweenPrice[1] !== 0) {
            params.min_price = { between: betweenPrice };
        }

        params.and = [];
        forEach(properties, (value) => {
            if (value.length > 0) {
                params.and.push({ 'configuration_property.property_value_id': value });
            }
        });

        getConfigurationProductListingApi(JSON.stringify(params), limit, offset, sort, locale)
            .then((response) => response.data)
            .then((product) => {
                dispatch(getProductListingSuccess(product));
            })
            .catch((e) => {
                if (axios.isCancel(e)) {
                    console.log('Canceled getConfigurationList');
                } else {
                    dispatch(getProductListingFailure());
                }
            });
    };
}

// GET_MAX_PRICE_REST_REQUEST
export function getMaxpriceRequest() {
    return {
        type: actionCreator(CatalogTypes.GET_MAX_PRICE_REST, 'req'),
    };
}

// GET_MAX_PRICE_REST_SUCCESS
export function getMaxpriceSuccess(data) {
    return {
        type: actionCreator(CatalogTypes.GET_MAX_PRICE_REST, 'res'),
        data,
    };
}

// GET_MAX_PRICE_REST_FAILURE
export function getMaxpriceFailure(error) {
    return {
        type: actionCreator(CatalogTypes.GET_MAX_PRICE_REST, 'err'),
        error,
    };
}

export function getMaxPrice(params) {
    return (dispatch) => {
        dispatch(getMaxpriceRequest());

        getMaxPriceApi(JSON.stringify(params))
            .then((response) => response.data)
            .then((maxpriceProduct) => {
                dispatch(getMaxpriceSuccess({ maxpriceProduct }));
                dispatch(updateCatalogPrice([0, Math.ceil(maxpriceProduct?.max_min_price || 100)]));
                dispatch(setDefaultPriceRest([0, Math.ceil(maxpriceProduct?.max_min_price || 100)]));
            })
            .catch((e) => {
                if (axios.isCancel(e)) {
                    console.log('Canceled getConfigurationData');
                } else {
                    dispatch(getMaxpriceFailure());
                }
            });
    };
}

export function getConfigurationData(locale, keepFilters) {
    return (dispatch) => {
        dispatch(getConfigurationList(locale));
        dispatch(getFilterData(keepFilters));
    };
}

// GET_RESULT_SEARCH_REST_REQUEST
export function getResultSearchRequest() {
    return {
        type: actionCreator(CatalogTypes.GET_RESULT_SEARCH_REST, 'req'),
    };
}

// GET_RESULT_SEARCH_REST_SUCCESS
export function getResultSearchSuccess(data) {
    return {
        type: actionCreator(CatalogTypes.GET_RESULT_SEARCH_REST, 'res'),
        data,
    };
}

// GET_RESULT_SEARCH_REST_FAILURE
export function getResultSearchFailure(error) {
    return {
        type: actionCreator(CatalogTypes.GET_RESULT_SEARCH_REST, 'err'),
        error,
    };
}

export function getResultSearchData(searchField, lang) {
    return (dispatch, getState) => {
        dispatch(getResultSearchRequest());
        const { limit, offset, sort } = getState().catalog.restFilter;

        const params = {};
        // params.or = [{ be_fr: { operator: 'typing', value: searchField } }, { be_nl: { operator: 'typing', value: searchField } }];
        params[underLocale(lang)] = { operator: 'typing', value: searchField };
        params.reseller = process.env.NEXT_PUBLIC_SHOP_ID;

        const betweenPrice = getState().catalog.restFilter.price;
        if (betweenPrice.length > 0 && betweenPrice[1] !== 0) {
            params.min_price = { between: betweenPrice };
        }

        getResultSearchApi(JSON.stringify(params), limit, offset, sort, lang)
            .then((response) => response.data)
            .then((resultSearch) => {
                dispatch(getResultSearchSuccess(resultSearch));
            })
            .catch((e) => {
                if (axios.isCancel(e)) {
                    console.log('Canceled getResultSearchData');
                } else {
                    dispatch(getResultSearchFailure());
                }
            });
    };
}

// GET_MAX_PRICE_REST_SEARCH_PAGE_REQUEST
export function getMaxpriceSearchPageRequest() {
    return {
        type: actionCreator(CatalogTypes.GET_MAX_PRICE_REST_SEARCH_PAGE, 'req'),
    };
}

// GET_MAX_PRICE_REST_SEARCH_PAGE_SUCCESS
export function getMaxpriceSearchPageSuccess(data) {
    return {
        type: actionCreator(CatalogTypes.GET_MAX_PRICE_REST_SEARCH_PAGE, 'res'),
        data,
    };
}

// GET_MAX_PRICE_REST_SEARCH_PAGE_FAILURE
export function getMaxpriceSearchPageFailure(error) {
    return {
        type: actionCreator(CatalogTypes.GET_MAX_PRICE_REST_SEARCH_PAGE, 'err'),
        error,
    };
}

export function getMaxPriceSearchPage(searchField, lang) {
    return (dispatch) => {
        dispatch(getMaxpriceSearchPageRequest());
        const data = {};
        data[underLocale(lang)] = { operator: 'typing', value: searchField };

        dispatch(updateCatalogPrice([]));
        dispatch(setDefaultPriceRest([]));

        getMaxPriceSearchPageApi(JSON.stringify(data))
            .then((response) => response.data)
            .then((maxpriceSearchPage) => {
                dispatch(getMaxpriceSearchPageSuccess({
                    maxpriceSearchPage,
                }));

                if (maxpriceSearchPage) {
                    const maxMinPrice = typeof maxpriceSearchPage.max_min_price === 'number' ? maxpriceSearchPage.max_min_price : maxpriceSearchPage.max_min_price[0];

                    const rangePrice = maxMinPrice ? [0, Math.ceil(maxMinPrice)] : [];

                    dispatch(updateCatalogPrice(rangePrice));
                    dispatch(setDefaultPriceRest(rangePrice));
                }
            })
            .catch((e) => {
                console.log(e);
                if (axios.isCancel(e)) {
                    console.log('Canceled getMaxPriceSearchPage');
                } else {
                    dispatch(getMaxpriceSearchPageFailure());
                }
            });
    };
}

// SET_PAGINATION_REST
export function setPaginationRest(page) {
    return {
        type: CatalogTypes.SET_PAGINATION_REST,
        page,
    };
}

// SELECT_SORT_REST
export function selectsortRest(sort) {
    return {
        type: CatalogTypes.SELECT_SORT_REST,
        sort,
    };
}

// SET_PRODUCTGROUP_ID
export function setProductGroup(subCatalog, page) {
    return {
        type: CatalogTypes.SET_PRODUCTGROUP_ID,
        subCatalog,
        page,
    };
}

export function setSubSub(sub, page) {
    return {
        type: CatalogTypes.SET_SUB_SUB_ID,
        sub,
        page,
    };
}

// SET_SEGMENT_ID
export function setSegmentId(segment, page) {
    return {
        type: CatalogTypes.SET_SEGMENT_ID,
        segment,
        page,
    };
}

// SET_EMPTY_PROPERTY
export function setEmptyProperty() {
    return {
        type: CatalogTypes.SET_EMPTY_PROPERTY,
    };
}

export function setEmptyFilter() {
    return {
        type: CatalogTypes.SET_EMPTY_FILTER,
    };
}

// SET_EMPTY_OFFSET
export function setEmptyOffset() {
    return {
        type: CatalogTypes.SET_EMPTY_OFFSET,
    };
}

// SET_EMPTY_SORT
export function setEmptySort(EmptySort) {
    return {
        type: CatalogTypes.SET_EMPTY_SORT,
        EmptySort,
    };
}

// SET_EMPTY_MAX_PRICE
export function setEmptyMaxprice(EmptyMaxprice) {
    return {
        type: CatalogTypes.SET_EMPTY_MAX_PRICE,
        EmptyMaxprice,
    };
}

export function setCategoryLevel(level) {
    return {
        type: CatalogTypes.SET_CATEGORY_LEVEL,
        level,
    };
}
